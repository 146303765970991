import React from "react"
import { graphql } from "gatsby"
import {Styling} from '../../styles/pages/shop-category.styled'
import HeroImageTitle from '../../components/reusable/HeroImageTitle'
import CategoryFilters from '../../components/reusable/CategoryFilters'
import ProductsGridViewMore from '../../components/reusable/ProductsGridViewMore'
import Seo from '../../components/global/seo'

const SamplePacks = ({data}) => {

  return (
    <Styling>
      <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} />
      <HeroImageTitle title="Merch" hero={data?.wpPage?.acf_hero?.heroImage?.localFile} />
      {/* <CategoryFilters filters={data.filters.nodes} base='merch' /> */}
      <ProductsGridViewMore products={data.products.nodes}  title="Merch" noFilters={true}/>
    </Styling>
  )
}

export default SamplePacks

export const query = graphql`
  {
    wpPage(title: {eq: "Merch"}) {
      id
      seo {
        metaDesc
        title
      }
      acf_hero {
        heroImage {
          localFile{
            childImageSharp {
              gatsbyImageData( 
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    filters: allWpMerchCategory {
      nodes {
        slug
        name
      }
    }
    products: allWpProduct(
      filter: {productCategories: {nodes: {elemMatch: {name: {eq: "Merch"}}}}}
    ) {
      nodes {
        id
        title
        slug
        acf_product_info {
          merch {
            price
            images {
              image {
                localFile{
                  childImageSharp {
                    gatsbyImageData( 
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`